<script>
import countTo from "vue-count-to";
import Statistics from '@/services/Statistics';
import General from '@/services/General';

/**
 * Stat component
 */
export default {
    components: {
        countTo
    },
    data() {
        return {
            showRegisteredUsers: false,
            user_count: 0,
            user_count_weekly: 0,
            orders_total: 0.00,
            orders_count: 0,
            change_percent: 0,
            days: 30,
            graph_days:30,
            graph_months: 12,
            graph_type:'daily',
            graph_data_type:'orders',
            showLoader:false,
            series: [{
                data: [0]
            }],
            orders_merchandising:[],
//            showOrdersStatistics: process.env.VUE_APP_SHOW_ORDER_STATS,
            showOrdersStatistics: false,
            options: {
            chart: {
              id: 'vuechart-example'
            },
            xaxis: {
              categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
            }
          },
      /*    series: [{
            name: 'series-1',
            data: [30, 40, 45, 50, 49, 60, 70, 91]
          }],

            chartOptions: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            */

            salesSeries: [
                {
                    name: 'Merchandising count',
                    type: 'line', // Specify type as line
                    data: [] // Initialize with empty data
                },
                {
                    name: 'Merchandising sum',
                    type: 'column', // Specify type as column
                    data: [] // Initialize with empty data
                }
            ],


            salesChartOptions: {
                chart: {
                    type: 'line', // Default type (can be overridden by series)
                    height: 390,
                    stacked: false, // Disable stacking for mixed charts
                    toolbar: {
                        show: false // Hide toolbar
                    }
                },
                stroke: {
                    width: [2, 0] // Line stroke for the first series, no stroke for the second (column)
                },
                plotOptions: {
                    bar: {
                        columnWidth: '50%' // Adjust column width
                    }
                },
                dataLabels: {
                    enabled: false // Disable data labels
                },
                fill: {
                    opacity: [0.85, 0.25, 1]
                },
                colors: ['#5b73e8', '#e8e9eb', '#f1b54d'],
                labels: [], // Initialize with empty labels
                xaxis: {
                    type: 'category' // Use category for date labels
                },
                yaxis: [
                    {
                        title: {
                            text: ''
                        }
                    },
                    {
                        opposite: true,
                        title: {
                            text: ''
                        }
                    }
                ],
                tooltip: {
                    shared: true,
                    intersect: false
                },
                animations: {
                    enabled: true,
                    easing: 'linear',
                    speed: 1500,
                    animateGradually: {
                        enabled: true,
                        delay: 1500
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 1500
                    }
                }
            },


            growthChartOptions: {
                fill: {
                    colors: ["#f1b44c"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12,13],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            orderseries: [70],
            orderRadial: {
                fill: {
                    colors: ["#34c38f"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        }
                    }
                }
            },
            customerseries: [55],
            customerRadial: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        }
                    }
                }
            }
        };
    },
    async created() {
        this.showRegisteredUsers = await this.checkObjectAccessCall('kpi.dashboard.registered_users');
        // this.showActiveSession = await this.checkObjectAccessCall('kpi.dashboard.active_session');
        // this.showOrders = await this.checkObjectAccessCall('kpi.dashboard.orders');
        // this.showTicketing = await this.checkObjectAccessCall('kpi.dashboard.ticketing');

    },
    async mounted() {
        this.getOrdersStatisticsByTypeTotalCount();
        this.getOrdersTotalsStatistics();
        this.getOrdersStatisticsByType();
        this.getCustomerStatistics();
    },
    methods : {
        async getCustomerStatistics() {
            try {
                await Statistics.getCustomerStatistics(this.days, {})
                    .then((response) => {
                        this.user_count = response.data.data.user_count;
                        this.user_count_weekly = response.data.data.user_count_weekly;
                    })
                    .catch((error) => {
                        this.error = error.response.data.error
                            ? error.response.data.error
                            : "";
                        this.failedmsg(this.error);
                    })
                    .finally(() => {});
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.failedmsg(this.error);
            }
        },

        async getOrdersStatisticsByTypeTotalCount() {
            try {
                const response = await Statistics.getOrdersStatisticsByType(this.graph_days, this.graph_type, {});
                if (response.data.data.data.length > 0) {
                    this.showOrdersStatistics = true;
                } else {
                    this.showOrdersStatistics = false;
                }
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.failedmsg(this.error);
                this.showOrdersStatistics = false;
            }
        },

        async getOrdersTotalsStatistics(){
            try {
                await Statistics.getOrdersTotalStatistics(this.days,{

                }).then((response) => {
                    let orders_total_temp = response.data.data.orders_total;
                    this.orders_total = parseFloat(orders_total_temp)
                    this.orders_count = response.data.data.orders_count;
                    this.change_percent = response.data.data.change_percent;

                    let orders_per_day = response.data.data.orders_per_day;

                    this.series[0].data.splice(0, 1);
                    orders_per_day.forEach((order) => {
                        //orders_temp.push(parseFloat(order.orders_total));
                        this.series[0].data.push(parseFloat(order.orders_total))
                    });

                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }).finally(() => {
                    this.updateSeriesLine();
                })

            } catch (error) {
                //console.log(error)
            }
        },



        async getOrdersStatisticsByType(){
            this.showLoader = true;
            try {
                await Statistics.getOrdersStatisticsByType(this.graph_days, this.graph_type,{

                }).then((response) => {
                    this.updateSalesChart(response.data.data);
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }).finally(() => {
                     this.showLoader = false;
                })

            } catch (error) {
                //console.log(error)
            }
        },



        updateSeriesLine() {
            if (this.$refs.realtimeChart) {
                this.$refs.realtimeChart.updateSeries([{
                    data: this.series[0].data,
                }], false, true);
            }
        },

        goToURL(url){
            window.location.href = '/'+url;
        },

        updateSalesChart(data) {
            if (!data || !data.data || data.data.length < 2) {
                console.error("Invalid data format");
                return;
            }

            const countData = data.data[0].data.map(value => parseFloat(value));


            const sumData = data.data[1].data.map(value => parseFloat(value));

            this.salesSeries = [
                {
                    name: 'Merchandising count',
                    type: 'line',
                    data: countData
                },
                {
                    name: 'Merchandising sum',
                    type: 'column',
                    data: sumData
                }
            ];

            // Update the chart options
            this.salesChartOptions = {
                labels: data.labels || [],
                colors: ['#5b73e8', '#4a5cb6', '#f1b54d'],
                stroke: {
                    width: [2, 0]
                },
                fill: {
                    opacity: [0.85, 0.25]
                },
                yaxis: [
                    {
                        title: {
                            text: 'Count'
                        }
                    },
                    {
                        opposite: true,
                        title: {
                            text: 'Sum'
                        }
                    }
                ]
            };

        },


        getGraphStatistics(){
         if(this.graph_data_type == "orders"){
            this.getOrdersStatisticsByType(this.graph_days, 'daily');
         }

        },

       getOrdersStatistics(){
            if(this.graph_data_type == "orders"){
                 if(this.graph_type == "daily"){
                    this.getOrdersStatisticsByType(this.graph_days, 'daily');
                 }
                 else if(this.graph_type == "monthly"){
                    this.getOrdersStatisticsByType(this.graph_months, 'monthly'); //create this!
                 }
             }

        },



    }
};
</script>

<template>
<div class="row">

    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body elementPointed" @click="goToURL('orders')">
                <div class="float-end mt-2">
                    <i class="fas fa-credit-card fa-3x"></i>
                   <!--  <apexchart v-if="orders_total > 0" ref="realtimeChart" class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptions" :series="series"></apexchart>
                    <i v-else class="fas fa-credit-card fa-3x"></i> -->
                </div>
                <div>
                    <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">
                            MKD<countTo :startVal="0.00" :endVal="orders_total" :decimals="2" decimal="." :duration="2000"></countTo>
                        </span>
                    </h4>
                    <h5 class="text-muted mb-0">Orders ({{days}} days)</h5>
                </div>
                <p class="text-muted mt-3 mb-0">
                      <!-- <span class="me-1 text-primary font-size-16">{{orders_count > 0 ? orders_count : 0 }}</span> total orders -->

                     <span v-if="change_percent === null" class="me-1">
                         <i class="fas fa-info-circle me-1 font-size-16"></i>No data to compare with
                     </span>
                     <span v-else-if="change_percent >= 0" class="text-success me-1">
                        <i class="mdi mdi-arrow-up-bold me-1 font-size-16"></i>{{change_percent}}% vs. previous {{days}} days
                     </span>
                     <span v-else class="text-danger me-1 font-size-16">
                        <i class="mdi mdi-arrow-down-bold me-1 font-size-16"></i>{{change_percent}}% vs. previous {{days}} days</span>

                </p>
            </div>
        </div>
        <div class="card">
            <div class="card-body elementPointed" @click="goToURL('customers')">
                <div class="float-end mt-2">
                    <i class="fas fa-users fa-3x"></i>
                </div>
                <div>
                    <h4 class="mb-1 mt-1">
              <span data-plugin="counterup">
                <countTo
                        :startVal="0"
                        :endVal="user_count"
                        :duration="2000"
                ></countTo>
              </span>
                    </h4>
                    <h5 class="text-muted mb-0">Registered Users</h5>
                </div>
                <p class="text-muted mt-3 mb-0">
            <span v-if="user_count_weekly > 0" class="text-success me-1">
              <i class="mdi mdi-arrow-up-bold me-1 font-size-16"></i
              >{{ user_count_weekly }}
            </span>
                    <span v-else class="text-danger me-1 font-size-16">
              <i class="mdi mdi-arrow-down-bold me-1 font-size-16"></i
              >{{ user_count_weekly }}</span
                    >
                    last {{ days }} days
                </p>
            </div>
        </div>
    </div>
    <!-- end col-->




<!--!!!sales chart is used to plot customers and devices data also-->
    <!--  <div class="row"> -->
          <div class="col-xl-8" :style="{'width': '100%;'}">
              <div class="card">
                  <div class="card-body">
                      <div class="col-sm-4 float-start">
                          <template v-if="showOrdersStatistics == true">
                              <select v-model="graph_data_type" class="form-select"  @change="getGraphStatistics()">
                                  <option value="orders" selected>Orders</option>
                              </select>
                          </template>

                      </div>
                      <div class="col-sm-2 float-end">
                          <select v-model="graph_type" class="form-select"  @change="getOrdersStatistics()">
                              <option value="daily" selected>Daily</option>
                              <option value="monthly">Monthly</option>
                          </select>
                      </div><br>

                      <div class="mt-3 p-2" >
                          <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                              <!--  <b-spinner large></b-spinner> -->
                          </div>
                          <apexchart id="salesChart" data-colors='["--bs-primary", "#dfe2e6", "--bs-warning"]' class="apex-charts" ref="salesChart"
                                :options="salesChartOptions" :series="salesSeries" >
                          </apexchart>
                      </div>

                  </div> <!-- end card-body-->
              </div> <!-- end card-->
          </div> <!-- end col-->




</div> <!-- end row-->
</template>
